import React, { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import {Helmet} from "react-helmet";
import Carousel from 'nuka-carousel';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";

// import logo from './assets/logo.png';
import justin_coble from './assets/justin-coble-construction-casa-di-coble.jpg';
// import justin_coble_constructioin from './assets/justin-coble-constructioin.png';
import casa_di_coble_logo_stacked from './assets/casa-di-coble-logo-stacked.png';
import coble_construction_logo from './assets/coble-construction-logo.png';
import capstone_logo from './assets/capstone-logo.png';


import justin_coble_homes_1 from './assets/slides/justin_coble_homes_1.jpg';
import justin_coble_homes_2 from './assets/slides/justin_coble_homes_2.jpg';
import justin_coble_homes_3 from './assets/slides/justin_coble_homes_3.jpg';
import justin_coble_homes_4 from './assets/slides/justin_coble_homes_4.jpg';
import justin_coble_homes_5 from './assets/slides/justin_coble_homes_5.jpg';
import justin_coble_homes_6 from './assets/slides/justin_coble_homes_6.jpg';
import justin_coble_homes_7 from './assets/slides/justin_coble_homes_7.jpg';
import justin_coble_homes_8 from './assets/slides/justin_coble_homes_8.jpg';
import justin_coble_homes_9 from './assets/slides/justin_coble_homes_9.jpg';
import justin_coble_homes_10 from './assets/slides/justin_coble_homes_10.jpg';
import justin_coble_homes_11 from './assets/slides/justin_coble_homes_11.jpg';
import justin_coble_homes_12 from './assets/slides/justin_coble_homes_12.jpg';
import justin_coble_homes_13 from './assets/slides/justin_coble_homes_13.jpg';
import justin_coble_homes_14 from './assets/slides/justin_coble_homes_14.jpg';
import justin_coble_homes_16 from './assets/slides/justin_coble_homes_16.jpg';
import justin_coble_homes_19 from './assets/slides/justin_coble_homes_19.jpg';
import justin_coble_homes_20 from './assets/slides/justin_coble_homes_20.jpg';
import justin_coble_homes_21 from './assets/slides/justin_coble_homes_21.jpg';
import justin_coble_homes_22 from './assets/slides/justin_coble_homes_22.jpg';
import justin_coble_homes_23 from './assets/slides/justin_coble_homes_23.jpg';
import justin_coble_homes_24 from './assets/slides/justin_coble_homes_24.jpg';
import justin_coble_homes_25 from './assets/slides/justin_coble_homes_25.jpg';
import justin_coble_homes_26 from './assets/slides/justin_coble_homes_26.jpg';
import justin_coble_homes_27 from './assets/slides/justin_coble_homes_27.jpg';
import justin_coble_homes_28 from './assets/slides/justin_coble_homes_28.jpg';


import './App.scss'
import './App.scss'

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      marginMultiply:0,
      index: 0,
      formOpen: false
    }


  }


  onLeave(origin, destination, direction) {
    this.setState({
      index:destination.index,
      marginMultiply: (destination.index === 0) ? 0 : 2
    })
    // console.log('onLeave', { origin, destination, direction });
    // arguments are mapped in order of fullpage.js callback arguments do something
    // with the event
  }

  render() {
    //const pos = ['50% 50%'];
    // const positions = ['50% 50%','0% 0%','100% 0','0% 100%','0% 0%'];
    // let position = positions[this.state.index]
    //let svgs = [-7,-6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6].map((i,j) => {

      
    //   return (
    //     <svg className="logo" width="214.4px" height="195.6px" viewBox="0 0 214.4 195.6" style={{transformOrigin:position,left:i*this.state.marginMultiply+'px',transform:'rotate('+(i*this.state.marginMultiply)+'deg)'}}>
    //         <g className="st0">
    //           <path className="st1" d="M138.9,43.3c11.7,0,21.2,1.7,28.5,5.2s13,7.8,17.1,13.1c4.1,5.3,6.9,11,8.4,17.1c1.5,6.2,2.3,11.9,2.3,17.3
    //             v37.3c0,4.7,0.8,8,2.5,10c1.6,2,4.8,3,9.5,3h7.2v49.3h-91.9v-84.9c0-3.5-0.4-6.2-1.3-8.3c-0.8-2.1-1.9-3.7-3.2-4.8
    //             c-1.3-1.1-2.7-1.8-4.3-2.2c-1.6-0.4-3.1-0.5-4.6-0.5c-5.8,0-10.1,1.7-12.9,5c-2.8,3.4-4.2,8.1-4.2,14.3v19c0,4.7,0.8,8,2.5,10
    //             c1.6,2,4.8,3,9.5,3h9.8v49.3H0v-49.3h7.8c3.6,0,6.4-1.2,8.3-3.5c1.9-2.3,2.9-5.2,2.9-8.4l-0.3-70.1c0-5.1-1.6-8.9-4.8-11.4
    //             c-3.2-2.4-7-3.7-11.2-3.7H0V0h91.9v49.3c0,1.7-0.5,4.6-1.6,8.8c-1.1,4.2-3.1,8.3-6,12.5l2.7,1.3c4.8-8.7,11.2-15.6,19.3-20.8
    //             C114.3,45.9,125.2,43.3,138.9,43.3z"/>
    //         </g>
    //       </svg>
    //   )
    // });
    // let divs = [...Array(14).keys()].map(i => {
    //   return (
    //     <div />
    //   )
    // });
    // const greetings = ['hello','bonjour','hola','guten tag','ciao','olà','namaste','salaam'];
    // let greeting = greetings[Math.floor((Math.random()*greetings.length))]

    const anchors = ["home", "justin", "coble-construction", "capstone-bahamas", "casa-di-coble", "slides", "end"];
    let nextLink = (this.state.index === 6) ? 'home' : anchors[this.state.index + 1]

    return (
      <div className="App">
        <Helmet>
            <title>Justin Coble | Luxury Home Builder in the Bahamian Islands</title>
            <meta name="description" content="Luxury Home Builder in the Bahamian Island" />
        </Helmet>
        <header>
        <a data-role="none" href={'#'+nextLink} className={'arrow-down ' + nextLink}>
        <svg width="960px" height="560px" viewBox="0 0 960 560">
          <g id="Rounded_Rectangle_33_copy_4_1_">
            <path d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937
              c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937
              c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"/>
          </g>
          </svg>
            </a>

        </header>
        <ReactFullpage
            anchors={anchors}
          onLeave={this.onLeave.bind(this)}
          navigationTooltips={anchors}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div class="section carousel">
                  <div class="title">
                    <h1>Justin Coble</h1>
                  </div>

                <Carousel
                  withoutControls={true}
                  autoplay={true}
                  pauseOnHover={false}
                  animation="zoom"
                  speed="4000"
                  wrapAround={true}
                  zoomScale={0.5}
                  opacityScale="1"
                  transitionMode={"fade"}
                  style={{ width: "100vw", height: "100vh" }} >
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_1+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_2+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_3+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_4+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_5+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_6+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_7+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_8+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_9+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_10+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_11+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_12+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_13+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_14+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_16+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_19+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_20+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_21+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_22+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_23+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_24+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_25+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_26+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_27+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_28+')'}} />
                    </Carousel>
</div>
                
                <div className="section">
                  <div class="row">
                    <div class="col quater"><img class="portrait" alt="" src={justin_coble} /></div>
                    <div class="col three-quaters">
                    <h1>Justin Coble</h1>
                      <h2>For Justin Coble, pursuing his passion for building custom luxury homes began in the high-stakes central Florida real estate market, which ultimately evolved into bringing the same exacting standards and experience to the beautiful Bahamian islands.</h2>
                      <p><a href="mailto:justin@casadicoble.com">JUSTIN@CASADICOBLE.COM</a><br />
                      <a href="tel:242-376-8304">242-376-8304</a><br /></p>
                         </div>
                  </div>
                </div>

                <div className="section">
                  <div class="row">
                    <div class="col quater"><img src={coble_construction_logo} alt="Coble Constuction Logo" /></div>
                    <div class="col three-quaters">
                      <p>In 2006, Justin started his own firm, Coble Construction, completing high-end, superb-quality residences – many for professional athletes – in those same communities of Islewoth, Lake Butler Sound, Downtown Orlando, and in the bedroom community of Maitland.</p>
                      <p>The ongoing relationship with Tavistock was the catalyst that brought him to the Bahamas in 2010. The spectacular nature of the homes at Albany – some of the most lavish in the world – and his connection to Tavistock made the journey from the States to the Bahamas a natural evolution in his career.</p>
                          </div>
                  </div>
                </div>
                <div className="section">
                  <div class="row">
                    <div class="col quater"><img src={capstone_logo} alt="Capstone Bahamas Logo" /></div>
                    <div class="col three-quaters">
                      <p>In 2011, Justin co-founded Capstone Construction. Soon after, Capstone was building grand waterfront residences in the exclusive luxury resort community of Albany, set on 600 ocean-side acres on New Providence. From 2011-2020 Capstone became the premier builder in Albany www.alabanybahamas.com and completed 12 homes in Albany and Lyford cay from 7,000 to 25,000 sq. ft. and prices ranging from $6 to $28 million.</p>
                      <p>The collective vision of its founders – Joe Lewis and private investment organization Tavistock Group, along with golfing greats Tiger Woods and Ernie Els – Albany is one of the most sought-after combinations of surroundings, architecture, sporting amenities, and services, and home to sports legends and celebrities from around the globe.</p>
                      <p>It is that connection to Tavistock that is a common thread in Justin’s professional career. While working in Orlando, Florida, with Southpoint Homes as a Project Manager, Justin built custom and speculative homes in central Florida’s most exclusive neighborhoods, including the Windermere communities of Lake Butler Sound, and Tavistock’s Isleworth.</p>
                      <p>Prior to his six years with Southpoint Homes, Justin – a Florida native – started his career at London Bay Homes in Naples, Florida after graduating from the University of Florida with a degree in Building Construction.</p>
                   </div>
                  </div>
                </div>
                <div class="section">
                <div class="row">
                    <div class="col quater"><img src={casa_di_coble_logo_stacked} alt="Casa Di Coble Logo"  /></div>
                    <div class="col three-quaters">
                      <p>Justin’s new business venture, Casa Di Coble, Justin looks to continue the success he has had in the high residential market in Nassau while also branching out to the outer islands to pursue interest in the boutique hotel, hospitality market, along with residential projects.</p>
                      <p>Together with a hand-picked team, Justin and Casa Di Coble offer a unique ability to fuse high-end Caribbean style with the utmost level of North American luxury building standards.</p>
                      </div>
                  </div>
                </div>
                <div className="section">
                <div class="row">
                <div class="col">
                <Carousel
                  withoutControls={false}
                  autoplay={false}
                  pauseOnHover={false}
                  wrapAround={true}
                  opacityScale="1"
                  style={{ width: "100%", height: "80vh" }} >
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_1+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_2+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_3+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_4+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_5+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_6+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_7+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_8+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_9+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_10+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_11+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_12+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_13+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_14+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_16+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_19+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_20+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_21+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_22+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_23+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_24+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_25+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_26+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_27+')'}} />
                    <div class="img" style={{backgroundImage:'url('+justin_coble_homes_28+')'}} />
                  </Carousel>

                </div>
                </div>
                </div>
                <div className="section">
                  <div>
                    <h1>JUSTIN COBLE</h1>
                    <a href="tel:242-376-8304">242-376-8304</a><br />
                    <a href="mailto:justin@casadicoble.com">JUSTIN@CASADICOBLE.COM</a><br />
                    <a href="https://casadicoble.com">CASADICOBLE.COM</a>
                  </div>
                </div>
                
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>
    );
  }
}

export default App;
